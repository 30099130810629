<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo align-items-center d-flex flex-column">
          <Logo style="height: 100px; width: 100px" />
        </b-link>

        <b-card-title class="mb-1">
          {{ $t("welcome", { appName: appName }) }}
        </b-card-title>
        <b-card-text class="mb-2"> </b-card-text>

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group :label="$t('email')" label-for="login-email">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t("password") }}</label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>{{ $t("forgotPassword") }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t("rememberMe") }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button type="submit" variant="primary" block @click="login">
              {{ $t("login") }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-right mt-2">
          <b-link :to="{ name: 'auth-register' }">
            <span>&nbsp; {{ $t("register") }} </span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text"></div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <locale />
        </div>
        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button href="javascript:void(0)" variant="facebook">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="twitter">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="google">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="github">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
    <b-modal
      id="modal-code"
      ref="code-modal"
      ok-title="Ok"
      ok-only
      centered
      :title="$t('userNotActive')"
      @ok="approveUser"
    >
      <b-form>
        <b-form-group>
          <span>{{ $t("sendedCode") }}</span>
          <br />
          <label for="code">{{ $t("code") }}:</label>
          <b-form-input id="code" v-model="code" type="number" placeholder="" />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-plant"
      ref="plant-modal"
      :ok-title="$t('ok')"
      ok-only
      centered
      :title="$t('selectWorkingPlant')"
      @ok="selectPlant"
    >
      <b-form class="p-2">
        <b-form-group>
          <span>{{ $t("selectPlant") }}</span>
          <br />
          <div class="d-flex flex-column">
            <b-form-select v-model="selectedPlantId" class="w-100">
              <b-form-select-option
                v-for="plant in plants"
                :key="plant.id"
                :value="plant.id"
              >
                {{ plant.plantname }}
              </b-form-select-option>
            </b-form-select>
            <!-- <b-button
              v-for="plant in plants"
              :key="plant.id"
              class="mt-2"
              :variant="selectedPlantId == plant.id ? 'success' : 'primary'"
              @click="selectedPlantId = plant.id"
            >
              {{ plant.plantname }}
            </b-button> -->
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Logo from "@/@core/layouts/components/Logo.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import {
  BCard,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    Logo,
    Locale,
    BFormSelect,
    BFormSelectOption,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      code: "",
      token: "",
      userid: "",
      required,
      appName: $themeConfig.app.appName,
      appLogoImage: $themeConfig.app.appLogoImage,
      plants: [],
      selectedPlantId: 0,
      userData: {},
      location: {},
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  async mounted() {
    // await this.getLocation();
  },
  methods: {
    // async getLocation() {
    //   if (navigator.geolocation) {
    //     let location;
    //     await navigator.geolocation.getCurrentPosition(async (position) => {
    //       this.location = position;
    //     });
    //     return location;
    //   } else {
    //     return { loc: 0, long: 0 };
    //   }
    // },
    async approveUser() {
      const response = this.$http.put(
        `/Users/ApproveUser/${this.userid}`,
        this.code,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
    },
    selectPlant() {
      if (this.selectedPlantId != 0) {
        store.commit("app/UPDATE_PLANT", this.selectedPlantId);
        localStorage.setItem("plantid", this.selectedPlantId);
      } else {
        store.commit("app/UPDATE_PLANT", this.plants[0].id);
        localStorage.setItem("plantid", this.plants[0].id);
      }
      this.changeRoute();
    },
    changeRoute() {
      this.$router.replace(getHomeRouteForLoggedInUser()).then(async () => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `${this.$t("welcome", {
              appName: this.appName,
            })} ${this.userData.name || this.userData.fullname} `,
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("successfullyLogin"),
          },
        });
      });
    },
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        var { coords } = this.location;
        if (success) {
          useJwt
            .login({
              Email: this.userEmail,
              Password: this.password,
              Culinfo: "tr",
              LocLat: "0",
              LocLong: "0",
            })
            .then((response) => {
              if (response.data.token) {
                if (response.data.active) {
                  if (response.data.companyactive) {
                    this.userData = response.data;
                    this.userData.ability = [];

                    // New user ability after login

                    // `newAbility` will be available from successful Login response
                    const newAbility = [
                      {
                        action: "read",
                        subject: "Auth",
                      },
                    ];
                    if (this.userData.hasdairy) {
                      newAbility.push({
                        action: "read",
                        subject: "Dairy",
                      });
                    }
                    if (this.userData.hasmix) {
                      newAbility.push({
                        action: "read",
                        subject: "Mix",
                      });
                    }
                    if (this.userData.hasbeef) {
                      newAbility.push({
                        action: "read",
                        subject: "Beef",
                      });
                    }
                    if (this.userData.hassheep) {
                      newAbility.push({
                        action: "read",
                        subject: "Sheep",
                      });
                    }
                    if (this.userData.hasgoat) {
                      newAbility.push({
                        action: "read",
                        subject: "Goat",
                      });
                    }
                    if (
                      this.userData.usertype == 1 ||
                      this.userData.usertype == 2 ||
                      this.userData.usertype == 0
                    ) {
                      newAbility.push({
                        action: "read",
                        subject: "CompanyAdmin",
                      });
                    }
                    if (this.userData.usertype == "0") {
                      newAbility.push({
                        action: "read",
                        subject: "Admin",
                      });
                    }

                    this.userData.ability = newAbility;

                    useJwt.setToken(response.data.token);
                    localStorage.setItem(
                      "userData",
                      JSON.stringify(this.userData)
                    );
                    this.$store.commit(
                      "app/UPDATE_PICTURE_URL",
                      this.userData.pictureurl + "?" + Math.random()
                    ); // Update when logged in
                    this.$ability.update(newAbility);

                    store.dispatch("plantsModule/fetchPlants").then((x) => {
                      this.plants = x;
                      this.selectedPlantId = this.plants[0].id;
                      if (this.plants.length > 1) {
                        this.$refs["plant-modal"].show();
                      } else {
                        this.selectPlant();
                      }
                    });
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: this.$t("userExpired"),
                        icon: "AlertCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: this.$t("companyNotActive"),
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
              }
            })
            .catch((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("loginFail"),
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style>
::marker {
  display: none !important;
  list-style-type: none;
}
.dropdown {
  list-style: none;
}
</style>
